var refs = 0;
var dispose;
var content = require("!!../../../node_modules/css-loader/index.js??ref--5-oneOf-0-1!../../../node_modules/resolve-url-loader/index.js??ref--5-oneOf-0-2!../../../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-0-3!./widget.scss?useable");

if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) exports.locals = content.locals;

exports.use = exports.ref = function() {
	if(!(refs++)) {
		dispose = require("!../../../node_modules/style-loader/lib/addStyles.js")(content, {"attrs":{"data-id":"widget-webstyle-style"},"hmr":true});
	}

	return exports;
};

exports.unuse = exports.unref = function() {
  if(refs > 0 && !(--refs)) {
	   dispose();
		 dispose = null;
  }
};
if(module.hot) {
	var lastRefs = module.hot.data && module.hot.data.refs || 0;

	if(lastRefs) {
		exports.ref();
		if(!content.locals) {
			refs = lastRefs;
		}
	}

	if(!content.locals) {
		module.hot.accept();
	}

	module.hot.dispose(function(data) {
		data.refs = content.locals ? 0 : refs;

		if(dispose) {
			dispose();
		}
	});
}